import React from "react";
import {Typography} from "@material-ui/core";
import {WelcomeDashboardLayout} from "src/components/layout";

export default function App() {
  return (
    <WelcomeDashboardLayout preferRows>
      <Typography variant="h4" component="h1" gutterBottom>
        Sorry &ndash; diese Seite existiert nicht.
      </Typography>
    </WelcomeDashboardLayout>
  );
}
